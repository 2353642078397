




























import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';
import DnbToggle from '@/components/DnbToggle.vue';

export default Vue.extend({
  name: 'icons',
  components: { DnbToggle, DnbIcon },
  data() {
    return { icons: ICONS, stroke: true };
  },
  methods: {
    toggleStroke(value: boolean) {
      this.stroke = value;
    },
  },
});
